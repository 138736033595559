<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="New GRN"
          :showBtn="false"
          :showAddNew="true"
          title="GRN List"
          @onClickAddNewButton="onClickAddNewButton"
          :show-extra-btn="true"
          extra-btn-title="GRN from PO"
          @onClickExtraBtn="addNewFromPO"
      >
      </TitleButton>

      <DateQuerySetter @onClickGo="getGRNs"/>
    </div>

    <ListTable
        :grn-list="grnList"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>

    <GlobalLoader />
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/inventory/grn/ListingTable'
import Pagination from '@/components/atom/Pagination'
import {useRoute, useRouter} from 'vue-router';
import {
  ref,
  inject,
  onMounted,
  computed,
  watch
} from 'vue'

import handleInventory from '@/services/modules/inventory'

const route = useRoute();
const router = useRouter();
const loading = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchGRNList} = handleInventory()

const offset = ref(20);
const loader = ref(false);
let page = ref(1);
let grnList = ref({});
let companyId = computed(() => route.params.companyId);

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  if (page.value) query += '&page=' + page.value
  return query
}

function onClickAddNewButton() {
  router.push({name: `grn-add`, params: route.params, query: route.query})
}

function addNewFromPO() {
  router.push({name: `grn-po-add`, params: route.params, query: route.query})
}

async function getGRNs() {
  try {
    const res = await fetchGRNList(getQuery());
    if (res.status) {
      grnList.value = res.data;
    } else {
      grnList.value = {};
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function onPageChange() {
  let routeQuery  = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getGRNs();
  }, 100)
}

onMounted(async () => {
  await getGRNs();
})
</script>
