<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>GRN Date</th>
          <th>GRN No</th>
          <th>Supplier</th>
          <th>GRN Type</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in grnList.data" :key="i">
          <td> {{ item.grn_date }}</td>
          <td> {{ item.grn_number }}</td>
          <td> {{ item?.contact_profile?.full_name }}</td>
          <td> {{ item.grn_type }}</td>
          <td> {{ item.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm"
                    @click="goToEdit(item)"
                    style="cursor: pointer"
            >
              <i class="fas fa-eye"></i>
            </button>
            <button class="btn btn-secondary btn-sm"
                    @click="goToPrint(item)"
                    style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
       <p v-if="!grnList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";

const props  = defineProps({
  grnList     : Object,
});
const route  = useRoute();
const router = useRouter();

const goToEdit = (item) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let query = route.query;
  query.grnId = item.id;

  router.push({
    name: item.grn_type === "from_po" ? 'grn-po-add' : 'grn-add',
    params: param,
    query: query
  })
}

const goToPrint = (item) => {
  let param = {
    companyId: route.params.companyId,
    moduleId: route.params.moduleId,
    menuId: route.params.menuId,
    pageId: route.params.pageId
  }

  let query = route.query;
  query.grnId = item.id;

  router.push({
    name: 'grn-print',
    params: param,
    query: query
  })
}
</script>
